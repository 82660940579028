<template>
  <div>
    <span>
      <div>
        <table id="my-table">
          <thead>
            <tr>
              <th id="activity" style="align:center;"></th>

              <th style="align:center;"></th>
              <th
                v-if="report[0] && report[0].name == 'reports.year'"
                id="note"
                style="align:center;"
              ></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(obj, key) in report">
              <template v-for="(rep, y) in obj.childs">
                <tr id="countryRow" :key="key + 2 * y" v-if="y == 0 && obj.name != 'reports.year'">
                  <td class="countryRow" :colspan="3">{{ $lang(obj.value) }}</td>
                </tr>
                <tr
                  :id="obj.name != 'reports.year' ? 'countryRowData' : ''"
                  :key="key + y"
                  v-if="!rep.name.includes('temp')"
                  :y="y"
                >
                  <td
                    v-if="y == 0 && obj.name == 'reports.year'"
                    :rowspan="obj.childs ? rowspan(obj.childs) : 0"
                  >{{ obj.value }}</td>
                  <td
                    v-if="
                      !rep.name.includes('temp') &&
                        rep.name != 'reports.yearContry'
                    "
                  >{{ $lang(rep.name) }}</td>
                  <td v-if="!rep.name.includes('temp')">{{ rep.value }}</td>
                  <td v-if="rep.name == 'reports.yearContry'">{{ rep.childs[0].value }}</td>
                </tr>
              </template>
            </template>
            <template v-if="report[0].name != 'reports.country' && report.length > 1">
              <template v-for="(rep, y) in sumData.childs">
                <tr :key="key + y" v-if="!rep.name.includes('temp')">
                  <td v-if="y == 0" :rowspan="rowspan(sumData.childs)">{{ sumData.value }}</td>
                  <td v-if="!rep.name.includes('temp')">{{ $lang(rep.name) }}</td>
                  <td v-if="!rep.name.includes('temp')">{{ rep.value }}</td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import moment from "moment";
import globalStore from "@/store";
import { ResponsiveDirective } from "vue-responsive-components";
import NoData from "@/components/General/NoData";

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  props: {
    report: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  components: {
    NoData
  },
  data() {
    return {
      nameRoute: ""
    };
  },
  created() {},

  methods: {
    rowspan(obj) {
      var rowNum = 0;
      for (let i = 0; i < obj.length; i++) {
        if (!obj[i].name.includes("temp")) {
          rowNum++;
        }
      }
      return rowNum;
    },
    returnLastSum(rootName, name) {
      var sum = 0;
      var $this = this;

      for (let i = 0; i < $this.report.length; i++) {
        for (let j = 0; j < $this.report[i].childs.length; j++) {
          if ($this.report[i].childs[j].name == name) {
            if ($this.report[i].childs[j].value != null)
              sum += Number($this.report[i].childs[j].value);
            else return null;
          }
        }
      }
      return sum;
    }
  },
  computed: {
    sumData() {
      var totalSum = {};
      if (this.report.length > 1) {
        var $this = this;

        var yFrom = 0,
          yTo = 0;
        var yTo = Math.max.apply(
          Math,
          $this.report.map(function(o) {
            return Number(o.value);
          })
        );
        var yFrom = Math.min.apply(
          Math,
          $this.report.map(function(o) {
            return Number(o.value);
          })
        );

        var lastObject = {
          value: yFrom + "-" + yTo,
          name: "reports.year",
          childs: $this.$_.cloneDeep($this.report[0].childs)
        };

        for (let i = 0; i < lastObject.childs.length; i++) {
          if (
            lastObject.childs[i].name.includes("temp") ||
            !lastObject.childs[i].name.includes("%")
          )
            lastObject.childs[i].value = $this.returnLastSum(
              "",
              lastObject.childs[i].name
            );
        }
        totalSum = lastObject;
        var total = "";
        for (let i = 0; i < totalSum.childs.length; i++) {
          if (
            totalSum.childs[i].name.includes("totalCountKnown") ||
            totalSum.childs[i].name.includes("totalCountSuspects")
          )
            total = totalSum.childs[i].value;
        }
        for (let i = 0; i < totalSum.childs.length; i++) {
          if (
            totalSum.childs[i].name.includes("%") &&
            !totalSum.childs[i].name.includes("totalCountKnown") &&
            !totalSum.childs[i].name.includes("totalCountSuspects")
          )
            if (total != 0) {
              totalSum.childs[i].value = Math.round(
                parseFloat(
                  totalSum.childs.find(function(element) {
                    return (
                      element.name ==
                        totalSum.childs[i].name.replace("%", "temp") ||
                      element.name == totalSum.childs[i].name.replace("%", "")
                    );
                  }).value / total
                ) * 100
              );
            } else {
              totalSum.childs[i].value = 0;
            }
        }
      }
      return totalSum;
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  text-align: left !important;
}
.countryRow {
  text-align: center !important;
  font-weight: bold;
  background-color: rgb(176, 224, 230);
}
#countryRowData {
  td {
    width: 50%;
    text-align: center !important;
  }
}
</style>
